import React, { Component } from "react";
import { connect } from "react-redux";

class LayoutFooter extends Component {
  render() {
    return (
      <nav className={`layout-footer footer bg-${this.props.footerBg}`}>
        <div className='container-fluid container-p-x pb-3'>
          <a
            className='footer-link pt-3'
            href='https://stackocean.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            Metadata-Extractor (demonstrator) © stackOcean 2020
          </a>
          {/*  <a href="#link-1" className="footer-link pt-3">
            Impressum
          </a>
          <a href="#link-2" className="footer-link pt-3 ml-4">
            Datenschutzerklärung
          </a> */}
        </div>
      </nav>
    );
  }
}

export default connect(store => ({
  footerBg: store.theme.footerBg
}))(LayoutFooter);
